import { Dialog } from 'vant';
export default {
  components: {
    [Dialog.name]: Dialog
  },
  data() {
    return {
      show: true
    };
  },
  props: {
    title: String,
    message: String,
    showCloseIcon: Boolean,
    btnLogin: {
      type: Boolean,
      default: false
    },
    showCancelButton: {
      type: Boolean,
      default: true
    },
    showConfirmButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onCancel() {
      this.$emit('update:show', false);
      this.$emit('cancel');
    },
    onConfirm() {
      if (this.btnLogin) return;
      this.$emit('update:show', false);
      this.$emit('confirm');
    }
  }
};